// If you don't want to use TypeScript you can delete this file!
import React from 'react'
import { PageProps, graphql, Link } from 'gatsby'

import Layout from '../components/Layout'
import { AllBlogQuery } from '../../types/graphql-types'
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { Title, Section } from '../components/Typography'
import LabelIcon from '@material-ui/icons/LabelOutlined'
import AllBlogPost from '../components/AllBlogPost'
import { GatsbyAwesomePaginationContext } from '../utils/types'
import Pagination from '../components/Pagination'

const BlogList: React.FC<PageProps<
  AllBlogQuery,
  GatsbyAwesomePaginationContext
>> = ({ data, pageContext }) => {
  const posts = [
    ...data.allMicrocmsBlogs.edges,
    ...data.allWordpressJson.edges,
  ].slice(pageContext.skip, pageContext.skip + pageContext.limit)
  return (
    <Layout path="Blog" title="Blog">
      <Title>Blog</Title>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={8}>
          <AllBlogPost
            posts={posts}
            categories={data.allMicrocmsCategories.edges}
          />
          <Pagination {...pageContext} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Section>カテゴリー</Section>
          <List>
            {data.allMicrocmsCategories.edges.map(edge => (
              <ListItem
                button
                divider
                component={Link}
                to={`/blog/categories/${edge.node.categoriesId}`}
              >
                <ListItemIcon>
                  <LabelIcon />
                </ListItemIcon>
                <ListItemText>{edge.node.name}</ListItemText>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default BlogList

export const query = graphql`
  query AllBlog {
    allWordpressJson(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          excerpt {
            rendered
          }
          title {
            rendered
          }
          date
          categories
          jetpack_featured_media_url
        }
      }
    }
    file(relativePath: { eq: "logo_twitter.png" }) {
      childImageSharp {
        fixed(width: 150, height: 150) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMicrocmsBlogs(sort: { fields: date, order: DESC }) {
      edges {
        node {
          categories {
            name
            id
          }
          childConvertHtml {
            plainText
          }
          date
          blogsId
          title
          localThumbnail {
            childImageSharp {
              fixed(width: 150, height: 150, cropFocus: CENTER) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    allMicrocmsCategories {
      edges {
        node {
          name
          categoriesId
        }
      }
    }
  }
`
